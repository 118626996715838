// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/svg/red_circle.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/alert.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/info.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/svg/arrow-narrow-up-move.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/svg/close_cross.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./choose_wallet.scss"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>
  <require from="../../../components/number-format-eur"></require>
  <require from="../../../components/number-format-bsv"></require>
  <require from="../../../components/max-length"></require>
  <require from="../../../components/date-format-full-day"></require>
  <require from="../../../components/header-titles/header-title-choose-wallet/header-title-choose-wallet"></require>
  <require from="../../../components/ValueConverters/iban-converter"></require>
  <section id="choose_wallet" class="flex-column">
    <header-title-choose-wallet title.bind="title" menu-items.bind="listMenuItems" tabs.two-way="tabs" is-editing.two-way="isEditing" is-drop-down.two-way="isDropDown">
    </header-title-choose-wallet>
    <div class="scrollable flex-column">
      <div class="wallet_list flex-column">

      <!--ALL IN NON-EDIT-MODE-->

        <div id="non-edit-mode" class="account bsv flex-row"  
          repeat.for="account of state.wallets" 
          if.bind="(tabs == 0 || (tabs == 1 && !account.isBlockchain) || (tabs == 2 && account.isBlockchain)) && !isEditing"
          click.delegate="selectThisAccount(account, \$index)"
          class.bind="state.selectedAccountIndex === \$index ? 'active': ''">
          <div class="icon">
            <img src.bind="account.picture" if.bind="account.isBlockchain">
            <img class="bank" src.bind="account.picture" if.bind="!account.isBlockchain">
            <img src="${___HTML_LOADER_IMPORT_0___}" class="red_dot" if.bind="!account.isAvailableInLocalForage && account.isBlockchain" click.delegate="goToBitcoinImport()">
          </div>
          <div class="account_name flex-column">
              <p>\${account.name}</p>
              <p class="gray" if.bind="!account.isBlockchain && !account.isError && account.institutionId !== 'paypal_eu' && account.institutionId !== 'paypal'">\${account.identification | ibanConverter}</p>
              <p class="gray" if.bind="!account.isBlockchain && !account.isError && (account.institutionId === 'paypal_eu' || account.institutionId === 'paypal')">\${account.identification}</p>
              <p class="gray" if.bind="account.isBlockchain">\${account.identification}</p>
              <p class="gray" if.bind="!account.isBlockchain && account.isError">\${account.identification}</p>
          </div>
          <div class="balance flex-row" class.bind="(blurred) ? 'blurr' : ''">
            <p class="amount" if.bind="!account.isBlockchain  && account.currency !== 'EUR' && !account.isError">
              \${account.currency | currencyDenomination }
              \${account.balance | numberFormatEur:localeForCurrency}
            </p>
            <p class="amount" if.bind="!account.isBlockchain && account.currency === 'EUR' && !account.isError">
              \${account.balance | numberFormatEur:localeForCurrency}\${account.currency | currencyDenomination }
            </p>
            <p class="amount" if.bind="account.isBlockchain && !account.isError">
              \${account.balance | numberFormatBsv:localeForCurrency}
            </p>
            <p class="amount" if.bind="account.isError">
              <img src="${___HTML_LOADER_IMPORT_1___}">
            </p>
          </div>
        </div>
        <div id="non-edit-mode" class="account bsv flex-row active" click.delegate="addAccount()" if.bind="(state.wallets.length < 1 || bankNumber === 0 && tabs === 1) || (nbrWallets === 0 && tabs === 2)">
          <div class="icon">
            <img src="${___HTML_LOADER_IMPORT_2___}">
          </div>
          <div class="account_name no_account flex-column">
            <p t="profile.choose.choose_wallet_no_wallets">No account yet</p>
            <p t="profile.choose.choose_wallet_no_wallets_add" class="gray">Would you like to add one?</p>
          </div>
        </div>

    <!-- Edit mode -->
        <div id="edit-mode" 
          class="account bsv flex-row \${isEditing && hideCardContent ? 'paddingless' : 'paddinged'}"
          repeat.for="account of state.wallets"
          if.bind="(tabs == 0 || (tabs == 1 && !account.isBlockchain) || (tabs == 2 && account.isBlockchain)) && isEditing"
          class.bind="state.selectedAccountIndex === \$index ? 'active' : ''">
          <div class="icon" if.bind="!hideCardContent">
            <img src.bind="account.picture" if.bind="account.isBlockchain">
            <img class="bank" src.bind="account.picture" if.bind="!account.isBlockchain">
            <img src="${___HTML_LOADER_IMPORT_0___}" class="red_dot" if.bind="!account.isAvailableInLocalForage && account.isBlockchain" click.delegate="goToBitcoinImport()">
          </div>
          <div class="account_name flex-column" if.bind="!hideCardContent">
            <p>\${account.name}</p>
            <p class="gray">\${account.identification}</p>
          </div>
          <div class="balance flex-row" if.bind="!hideCardContent" class.bind="(blurred) ? 'blurr' : ''">
            <p class="amount" if.bind="!account.isBlockchain  && account.currency !== 'EUR' && !account.isError">
              \${account.currency | currencyDenomination }
              \${account.balance | numberFormatEur:localeForCurrency}
            </p>
            <p class="amount" if.bind="!account.isBlockchain && account.currency === 'EUR' && !account.isError">
              \${account.balance | numberFormatEur:localeForCurrency} \${account.currency | currencyDenomination }
            </p>
            <p class="amount" if.bind="account.isBlockchain && !account.isError">
              \${account.balance | numberFormatBsv:localeForCurrency}
            </p>
          </div>

          <!-- Edit mode -->

          <div class="bitcoin_edit_icons" if.bind="!hideCardContent">
            <img id="arrow-narrow-up-move" if.bind="(isEditing && firstEdit && !hideCardContent && !account.isAvailableInLocalForage) || isEditing && !account.isBlockchain && isError" class="action" src="${___HTML_LOADER_IMPORT_3___}" click.delegate="goToBitcoinImport(account)"/>
            <img id="close_cross" if.bind="isEditing && firstEdit && !hideCardContent" class="action" src="${___HTML_LOADER_IMPORT_4___}" click.delegate="showContent('bankorpaymail_r_u_sure', \$index)"/>
          </div>


          <!-- Unlink mode - Bank -->
          <div if.bind="isEditing && unlinkModeIndex === \$index && hideCardContent && !account.isBlockchain" class="unlink flex-column">
            <p class="grey" t="profile.choose.choose_wallet.unlink_bank">DO YOU REALLY WANT TO UNLINK THIS BANK?</p>
            <div class="account_name flex-column">
              <p>\${account.name}</p>
              <p class="gray">\${account.identification}</p>
            </div>
            <div class="flex-row">
              <button class="btn r_u_sure_btn yes" click.delegate="unlinkBank(account)" t="home.yes">YES</button>
              <button class="btn r_u_sure_btn no" click.delegate="showContent('bankorpaymail_editmode', \$index)" t="home.no">NO</button>
            </div>
          </div>

          <!-- Unlink mode - Bitcoin -->
          <div if.bind="isEditing && unlinkModeIndex === \$index && hideCardContent && account.isBlockchain" class="unlink flex-column">
            <p class="grey" t="profile.choose.choose_wallet.unlink_bitcoin">DO YOU REALLY WANT TO UNLINK THIS BITCOIN WALLET?</p>
            <div class="account_name flex-column">
              <p>\${account.name}</p>
              <p class="gray">\${account.identification}</p>
            </div>
            <div class="flex-row">
              <button id="profile.choose_wallet.button.unlink" class="btn r_u_sure_btn yes" click.delegate="unlinkWallet(account)">YES</button>
              <button id="profile.choose_wallet.button.showContent" class="btn r_u_sure_btn no" click.delegate="showContent('bankorpaymail_editmode', \$index)">NO</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dropdown_wallet">
      <ul if.bind="isDropDown">
        <li if.bind="!isEditing" t="profile.choose.choose_wallet.edit_account" click.delegate="isEditing = !isEditing"></li>
        <li if.bind="isEditing" t="contacts.stop_editing" click.delegate="isEditing = !isEditing">></li>
        <li click.delegate="addAccount()" t="profile.choose.choose_wallet.add_account"></li>
      </ul>
    </div>
  </section>
</template>
`;
// Exports
export default code;